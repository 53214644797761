import React, { useContext } from "react"
import {
  Typography,
  Box,
  FormControl,
  Grid,
  TextField,
  useMediaQuery,
  Container,
} from "@material-ui/core"
import { Formik, Form, ErrorMessage, FieldArray, Field } from "formik"
import { useTheme } from "@material-ui/styles"
import FormErrors from "../../FormErrors"
import * as Yup from "yup"
import Paper from "../../Paper"
import { useStyles } from "./style"

import Button from "../../Button"
import { Close, Add } from "@material-ui/icons"

import LoanContext from "../../../context/loan/loanContext"
import AlertContext from "../../../context/alert/alertContext"
import SocialShare from "../../../components/SocialShare"

const ReferLayout = () => {
  const { isLoading, refer } = useContext(LoanContext)
  const { setAlert } = useContext(AlertContext)


  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const message = `I just applied for a loan to finance my education with this cool new platform, EduCollect. They offer a relatively low interest rate and application was super quick and easy. You should check it out. Visit https://educollectfinance.com for more. Cheers`
  let initialValues = { message, userName: "", userEmail: "",  friends: [""] }

  const validationSchema = Yup.object({
    message: Yup.string().required("Message is empty"),
    userEmail: Yup.string()
      .email("Invalid email format!")
      .required("Email is empty"),
    userName: Yup.string().required("Full name is empty"),
  })

  let passed = true
  const onSubmit = async (values, resetForm ) => {
    values.friends.forEach(friend => {
      if (friend === "" || friend.name === "" || friend.email === "") {
        passed = false
        setAlert("Please fill all fields", "error")
        return false
      }

      if (!/\S+@\S+\.\S+/.test(friend.email)) {
        passed = false
        setAlert("Avoid Invalid email format", "error")
        return false
      }
    })
    passed && (await refer(values, resetForm))
  }

  const url = 'https://educollectfinance.com'
  const classes = useStyles()

  return (
    <Box
      id="refer"
      position="relative"
      paddingTop={isMobile ? "8rem" : "9.5rem"}
      className={classes.root}
    >
      <Box className={classes.hero}>
        <Typography variant="h2" align="center" gutterBottom>
          Refer A Friend
        </Typography>
        <Typography className={classes.subTitle} align="center">
          Introduce the Educollect platform to friends and loved ones
          {!isMobile && <br />} you feel can benefit from such an amazing
          opportunity.
        </Typography>
      </Box>
      <Box className={classes.body}>
        <Container maxWidth={"md"}>
          <Paper padding={25} borderRadius={1}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              // onSubmit={onSubmit}
              onSubmit={ (initialValues, { resetForm }) => {
                onSubmit(initialValues, resetForm)
              }}
              render={({ values, getFieldProps, resetForm }) => (
                <Form>
                  <FormControl className={classes.formControl}>
                    <Typography className={classes.label}>Message</Typography>
                    <TextField
                      name="message"
                      id="message"
                      fullWidth
                      multiline
                      rows={4}
                      {...getFieldProps("message")}
                      variant="outlined"
                      size="small"
                    />
                    <ErrorMessage name="message" component={FormErrors} />
                  </FormControl>
                  <Grid
                    container
                    spacing={isMobile ? 2 : 4}
                    style={{ marginTop: "1rem" }}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl className={classes.formControl}>
                        <Typography className={classes.label}>
                          Your Full name
                        </Typography>
                        <Field
                          name="userName"
                          id="userName"
                          {...getFieldProps("userName")}
                          className={classes.textField}
                        />
                        <ErrorMessage name="userName" component={FormErrors} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl className={classes.formControl}>
                        <Typography className={classes.label}>
                          Your Email
                        </Typography>
                        <Field
                          name="userEmail"
                          id="userEmail"
                          {...getFieldProps("userEmail")}
                          className={classes.textField}
                        />
                        <ErrorMessage name="userEmail" component={FormErrors} />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <FieldArray
                    name="friends"
                    render={arrayHelpers => (
                      <div>
                        {values.friends && values.friends.length > 0 ? (
                          <Box marginTop={1}>
                            {values.friends.map((friend, index) => (
                              <Grid
                                container
                                spacing={isMobile ? 2 : 4}
                                key={index}
                              >
                                <Grid item xs={12} sm={12} md={6}>
                                  <FormControl className={classes.formControl}>
                                    <Typography className={classes.label}>
                                      Friend's Name
                                    </Typography>

                                    <Field
                                      name={`friends[${index}].name`}
                                      className={classes.textField}
                                    />
                                  </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                  <FormControl className={classes.formControl}>
                                    <Typography className={classes.label}>
                                      Friend's email Address
                                    </Typography>
                                    <Field
                                      name={`friends[${index}].email`}
                                      className={classes.textField}
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            ))}
                            <Box marginTop={2}>
                              <Typography
                                display="inline"
                                variant="body2"
                                color="primary"
                                style={{
                                  fontSize: "12px",
                                  marginTop: "0.5rem",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  arrayHelpers.push({ name: "", email: "" })
                                }
                              >
                                <Add style={{ fontSize: "0.8rem" }} /> Add
                                another friend
                              </Typography>
                              {values.friends.length > 1 && (
                                <Typography
                                  display="inline"
                                  variant="body2"
                                  color="primary"
                                  style={{
                                    fontSize: "12px",
                                    marginLeft: "1rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => arrayHelpers.remove(values.friends.length - 1)}
                                >
                                  {/* arrayHelpers.remove() */}
                                  <Close style={{ fontSize: "0.8rem" }} />{" "}
                                  Remove friend
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        ) : (
                          <button
                            type="button"
                            onClick={() =>{
                              console.log(values)
                              arrayHelpers.push({ name: "", email: "" })
                            }
                             
                            }
                          >
                            Add a friend
                          </button>
                        )}
                        <Box  marginTop={3} className={classes.action}>
                          <Typography
                            style={{fontWeight: "600", marginRight: "1rem", cursor:"pointer"}}
                            onClick={() =>
                              resetForm({
                                initialValues: {
                                  message,
                                  userName: "",
                                  userEmail: "",
                                  friends: [""],
                                },
                              })
                            }
                          >
                            Cancel
                          </Typography>
                         
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isLoading}
                            loading={isLoading}
                          >
                            Send
                          </Button>
                        </Box>
                      </div>
                    )}
                  />
                </Form>
              )}
            />
            <Box marginTop={5}>
              <SocialShare shareUrl={url}/>
            </Box>
          </Paper>
        </Container>
      </Box>
    </Box>
  )
}

export default ReferLayout
