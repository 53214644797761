import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#F2F8FF",
  },
  hero: {
    marginBottom: "2em",
  },
  body: {
    padding: "0 0 5rem 0",
    "& p, li": {
      fontWeight: 400,
      fontSize: `1rem`,
    },
  },
  subTitle: {
    color: "#24292E",
    padding: "0 2rem",
  },
  formControl: {
    width: "100%",
  },
  label: {
    fontSize: "14px",
    fontWeight: "400",
    marginBottom: ".3rem",
  },
  action: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  textField: {
      height: "2.5rem",
      padding: "0 1rem",
  }
}))

export { useStyles }
